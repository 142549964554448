
@import "@/styles/_variables.scss";

.slate-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2.4rem;
}

