
@import "@/styles/_variables.scss";

.page {
  padding: 2.4rem;
  // background-color: rgba(255,255,255, 0.85);
  min-height: 100vh;
}

.page-slots > * {
  margin-bottom: 2.4rem;
}

