@import "./system-font.scss";

@import "variables";

@import "~bulma";
//@import "materia_overrides";
@import "~buefy/src/scss/buefy";

html {
  --body-font-size: 62.5%;
}

body {
  background-color: #eee;
  background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background: url(/images/Subtle-Prism.svg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #282828;
  // background-size: 16px 16px;

  font-size: 1.6rem;
  line-height: 1.4;
}

h1 { font-weight: bold; font-size: $size-1; }
h2 { font-weight: bold; font-size: $size-2; }
h3 { font-weight: bold; font-size: $size-3; }
h4 { font-weight: bold; font-size: $size-4; }
h5 { font-size: $size-5; }

.datepicker .dropdown-item,
.dropdown .dropdown-menu,
.datepicker .dropdown .dropdown-menu .has-link a,
.dropdown .dropdown-menu .has-link .datepicker a {
  z-index: 3050;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.box {
  padding: 1.2rem;
}

.notices {
  z-index: 9000;

  .snackbar {
    box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.75);
  }
}